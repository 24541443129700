import {useState} from 'react'

export default function useSortRanking(ranking) {

    const [rankingSort, setRankingSort] = useState(ranking)

    const sort_by = (field, reverse, primer) => {
      const key = primer
        ? function (x) {
            return primer(x.node[field])
          }
        : function (x) {
            return x.node[field]
          }
  
      reverse = !reverse ? 1 : -1
  
      return function (a, b) {
        return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a))
      }
    }
  
    
    function sortRanking(selectedOption) {
      let arrToSort = [...rankingSort]
  
      if (selectedOption === 'ocena_rosnaca') {
        const result = arrToSort.sort(sort_by('average', false, parseFloat))
        setRankingSort(result)
      }
      if (selectedOption === 'ocena_malejaca') {
        const result = arrToSort.sort(sort_by('average', true, parseFloat))
        setRankingSort(result)
      }
      if (selectedOption === 'ilosc_opinii_rosnaca') {
        const result = arrToSort.sort(sort_by('opinionsCount', false, parseInt))
        setRankingSort(result)
      }
      if (selectedOption === 'ilosc_opinii_malejaca') {
        const result = arrToSort.sort(sort_by('opinionsCount', true, parseInt))
        setRankingSort(result)
      }
      if (selectedOption === 'pozycja_rynkowa_rosnaca') {
        const result = arrToSort.sort(sort_by('position', true, parseInt))
        setRankingSort(result)
      }
      if (selectedOption === 'pozycja_rynkowa_malejaca') {
        const result = arrToSort.sort(sort_by('position', false, parseInt))
        setRankingSort(result)
      }
      if (selectedOption === 'wyplacone_odszkodowanie_rosnaca') {
        const result = arrToSort.sort(sort_by('sum', false, parseInt))
        setRankingSort(result)
      }
      if (selectedOption === 'wyplacone_odszkodowanie_malejaca') {
        const result = arrToSort.sort(sort_by('sum', true, parseInt))
        setRankingSort(result)
      }
    }
    return [rankingSort, sortRanking]
}
