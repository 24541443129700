import React from 'react'
import config from '../../../config.json'
import CheckedImg from '../../assets/images/icons/checked.svg'
import CotillionImg from '../../assets/images/zlota-dobra-polisa-2022-small.png'
import Button from '../Button/Button'
import Stars from '../Stars/Stars'
import { Checked, Cotillion, Description, Name, Number, Position, RankingRow, Result, Value } from './RankingItem.style'

export default function RankingItem({
  company,
  position,
  opinionsCount,
  average,
  sum,
  rank,
  slug,
  rankingType,
  formUrl,
  ctaDetailsUrl,
  ctaDetailsLabel,
  cotillion
}) {
  // company = company[0].toUpperCase() + company.slice(1)
  // rank = rank + 1

  const linkMap = {
    'ubezpieczenia-na-zycie': config.CALC_LINK_LIFE,
    'ubezpieczenia-zdrowotne': config.CALC_LINK_HEALTH,
    'ubezpieczenia-majatkowe': config.CALC_LINK_HH,
  }



  return (
    <RankingRow>
      <div className="row">
        <Number>{rank}</Number>
        <div className="col-xl-3 col-lg-7 col-md-12 d-flex align-items-center cell-border mt-3 justify-content-center justify-content-lg-start mb-md-3 mb-lg-0 mt-lg-0">
          <Name className="">{company}</Name>
          {cotillion ? (
          <Cotillion>
            <span>
              Najnowsze
              <br />
              odznaczenie
            </span>
            <br />
            <img src={CotillionImg} />
          </Cotillion>
          ) : ( null )}
        </div>
        <div className="col-xl-1 col-lg-2 col-md-6 d-flex justify-content-center justify-content-xl-end align-items-xl-center mt-2 mt-md-0">
          <Result>{new Intl.NumberFormat('pl-PL', {minimumFractionDigits: 1}).format(average)}/5</Result>
        </div>
        <div className="col-xl-2 col-lg-3 col-md-6 d-flex justify-content-center align-items-center mt-3 mt-md-0 mb-md-4 mb-lg-0 w-100 text-center text-lg-left">
          <Stars rating={average} opinions={opinionsCount} centerMobile={true} style={{ marginLeft: '50px' }} />
        </div>
        <div className="col-xl-2 col-lg-3 col-md-6 d-flex align-items-center mt-3 mt-md-3 p-md-4 p-lg-4 p-xl-0 mb-lg-0 mb-xl-3 w-100 text-center text-lg-left">
          <Position>
            <Value className="justify-content-center justify-content-lg-start">
              <Checked src={CheckedImg} /> nr {position}
            </Value>
            <Description className="justify-content-center justify-content-lg-start d-flex">
              Pozycja rynkowa w polsce
            </Description>
          </Position>
        </div>
        <div className="col-xl-2 col-lg-4 col-md-6 d-flex align-items-center mt-3 mt-lg-3 mt-xl-2">
          <Position>
            <Value className="justify-content-center justify-content-lg-start">
              <Checked src={CheckedImg} /> {sum} mln
            </Value>
            <Description className="justify-content-center justify-content-lg-start d-flex">
              Wypłacone odszkodowanie
              <br />
              (w ub. roku)
            </Description>
          </Position>
        </div>
        <div className="col-xl-2 col-lg-5 col-md-12 d-flex justify-content-center flex-column align-items-center mt-4 mt-lg-3 mt-xl-0 offset-lg-0">
          <Button
            text="Porównaj"
            internalLink={formUrl ? formUrl : null}
            externalLink={formUrl ? null : linkMap[rankingType]}
            padding="10px 20px 5px"
            margin="0 0 10px 0"
            width="100%"
          />
          <Button
            text={ctaDetailsLabel ? ctaDetailsLabel : "Zobacz szczegóły"}
            internalLink={ctaDetailsUrl ? ctaDetailsUrl : null}
            externalLink={ctaDetailsUrl ? null : `/towarzystwo-ubezpieczeniowe/${slug}/`}
            padding="10px 20px 5px"
            secondary={true}
            width="100%"
          />
        </div>
      </div>
    </RankingRow>
  )
}
