import { Col } from 'react-bootstrap'
import styled from 'styled-components'

import ArrowDownImg from '../../assets/images/home/arrow_down.svg'
import ArrowUpImg from '../../assets/images/home/arrow_up.svg'
import { layout, layoutMobile, fontSize, fontSizeMobile, fontFamily, color } from '../../assets/styles/variables'

export const Wrapper = styled(Col)`
  width: 100%;
  padding-top: ${layoutMobile.top};
  padding-bottom: ${layout.bottom};
`
export const Heading = styled.div`
  font-size: ${fontSize.h2};
  color: ${color.textHeader};
  font-weight: 700;
  font-family: ${fontFamily.fontPrimary};
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 991px) {
    font-size: ${fontSizeMobile.h2};
    text-align: center;
    margin-bottom: 1.5rem;
  }
`
export const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .select {
    display: flex;
    position: relative;

    label {
      color: #99a5bb;
      display: flex;
      padding: 17px 0 5px 0;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      margin-right: 20px;
      margin-bottom: 0.5rem;
    }
    select {
      padding: 10px 40px 10px 20px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 7px;
      border: 1px solid #e8edf3;
      background-color: #e8edf3;
      color: #053592;
      outline: none;
      max-width: 350px;
      position: relative;
      appearance: none;
      display: block;
      background: url(${ArrowDownImg}) no-repeat right #e8edf3;
      background-size: 14px;
      background-position-x: 95%;
      cursor: pointer;
      &:focus {
        background: url(${ArrowUpImg}) no-repeat right #e8edf3;
        background-size: 14px;
        background-position-x: 95%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 16px;
    margin-bottom: 40px;
    .select {
      flex-direction: column;
      padding: 0;
      label {
        padding: 0;
        margin: 0;
      }
      select {
        font-size: 11px;
        padding: 20px 40px 20px 20px;
        width: 100%;
      }
    }
  }
`

export const Dots = styled.div`
  display: flex;
  max-width: 200px;
  & > div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${color.grey};
    margin: 3px
  }
`