import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../Button/Button'

import { Heading, SelectWrapper, Wrapper, Dots } from './Ranking.style'
import RankingItem from './RankingItem'
import useSortRanking from './useSortRanking'

export default function Ranking({ ranking, allowSorting = false, heading = null, rankingButtonText= null, internalLink = null, type = null }) {
  const [rankingSorted, sortRanking] = useSortRanking(ranking)

  const handleSelectChange = (selectedOption) => {
    sortRanking(selectedOption)
  }

  return (
    <Row id={type}>
      <Wrapper>
        {heading && (
          <Row>
            <Col className="d-flex justify-content-center">
              <Heading>{heading}</Heading>
            </Col>
          </Row>
        )}
        {allowSorting && (
          <Row className="g-0 overflow-hidden">
            <Col>
              <SelectWrapper>
                <div className="select">
                  <label htmlFor="sort-select">Sortuj według:</label>
                  <select id="sort-select" onChange={(e) => handleSelectChange(e.target.value)}>
                    <option value="ocena_rosnaca">ogólna ocena: rosnąco</option>
                    <option value="ocena_malejaca" selected>
                      ogólna ocena: malejąco
                    </option>
                    <option value="ilosc_opinii_rosnaca">ilość opinii: rosnąca</option>
                    <option value="ilosc_opinii_malejaca">ilość opinii: malejąco</option>
                    <option value="pozycja_rynkowa_rosnaca">pozycja rynkowa w Polsce: rosnąca</option>
                    <option value="pozycja_rynkowa_malejaca">pozycja rynkowa w Polsce: malejąco</option>
                    <option value="wyplacone_odszkodowanie_rosnaca">
                      wypłacone odszkodowania (w ub. roku): rosnąco
                    </option>
                    <option value="wyplacone_odszkodowanie_malejaca">
                      wypłacone odszkodowania (w ub. roku): malejąco
                    </option>
                  </select>
                </div>
              </SelectWrapper>
            </Col>
          </Row>
        )}

        <Row>
          {rankingSorted.map((rank, i) => {
            return <RankingItem {...rank.node} rank={i + 1} key={rank.node.companyId} />
          })}
        </Row>

        {internalLink && (
          <>
          <Row className="mt-3 mt-lg-4">
            <Col className="d-flex justify-content-center">
              <Dots>
                <div></div>
                <div></div>
                <div></div>
              </Dots>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="d-flex justify-content-center">
              <Button internalLink={internalLink} text={rankingButtonText} secondary={true} width="inital" />
            </Col>
          </Row> 
          </>
        )}
      </Wrapper>
    </Row>
  )
}
