import styled from 'styled-components'

import { color } from '../../assets/styles/variables'

export const RankingRow = styled.div`
  position: relative;
  padding: 24px 15px 24px 50px;
  background-color: #ffffff;
  border-radius: 7px;
  margin-top: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);

  @media screen and (min-width: 1400px) {
    .cell-border {
      border-right: 1px solid #e4e4e4;
    }
  }
  @media screen and (max-width: 991px) {
    padding: 24px 15px;
    margin-bottom: 20px;
  }
`

export const Number = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 7px;
  background-color: ${color.primary};
  color: #ffffff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 991px) {
    top: -25px;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translateX(-50%);
  }
`
export const Name = styled.div`
  font-size: 18px;
  color: ${color.primary};
  font-weight: 700;
  margin-left: 20px;
  text-transform:capitalize;
  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
`
export const Result = styled.div`
  color: ${color.secondary};
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 10px 10px 10px;
  border-radius: 7px;
  min-width: 60px;
  max-height: 50px;
  border: 2px solid ${color.secondary};
  text-align: center;
  line-height: 1.4;

  @media screen and (min-width: 1200px) and (max-width: 1335px) {
    font-size: 14px;
    padding: 10px 7px;
  }
  @media screen and (max-width: 991px) {
    align-self: flex-start;
  }
`

export const Position = styled.div`
  font-size: 22px;
  color: ${color.primary};
  font-weight: 600;
  line-height: 1;
  width: 100% !important;
  @media (min-width: 992px) {
    text-align: left !important;
  }
`
export const Value = styled.div`
  display: flex;
  align-items: center;
`
export const Checked = styled.img`
  width: 16px;
  margin-right: 5px;
  position: relative;
  top: -2px;
`

export const Description = styled.div`
  font-weight: 600;
  color: #99a5bb;
  font-size: 13px;
  margin-bottom: 0;
  line-height: 1.2;
  margin-top: 4px;
`

export const Cotillion = styled.div`
  float: none;
  margin: 0;
  text-align: center;
  z-index: 1001;
  margin-left: 30px;

  span{
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    color: #546077;
    line-height: 12px;
  }

  img {
    z-index: 1001;
    max-width: 100px;
  }
`